export const homeObjOne = {
    id: 'about',
    lightBg: false,
    lightText : true,
    lightTextDesc: true,
    topLine: 'Premium Consulting',
    headline: 'Experienced In Various Industries',
    description: 'The team helped international clients succesfully launch ventures in agriculture, automotive, IT Development, pharmaceutical, and petroleum',
    buttonLabel: 'Get Started',
    imgStart: true,
    img: require('../../images/svg-1.svg').default,
    alt: 'Invest',
    dark: true,
    primary: true,
    darkText: false
}

export const homeObjTwo = {
    id: 'industries',
    lightBg: true,
    lightText : false,
    lightTextDesc: false,
    topLine: 'Premium Consulting',
    headline: 'Advice For General And Limited Partners',
    description: 'Get access to our exclusive consulting on strategy and tactics for initiating discussions with prospective investors',
    buttonLabel: 'Get Started',
    imgStart: false,
    img: require('../../images/undraw_pitching_re_fpgk.svg').default,
    alt: 'Invest',
    dark: false,
    primary: false,
    darkText: true
}

export const homeObjThree = {
    id: 'careers',
    lightBg: true,
    lightText : false,
    lightTextDesc: false,
    topLine: 'Prospective Careers',
    headline: 'Start Your Jouney At KUZOG',
    description: 'We are currently not hiring nut we are always seraching for engineers, IT & Security experts, and refractory brick technology experts. Please send all resumes to management@kuzog.com',
    buttonLabel: 'Contact Us',
    imgStart: false,
    img: require('../../images/undraw_online_resume_re_ru7s.svg').default,
    alt: 'Invest',
    dark: false,
    primary: false,
    darkText: true
}