import React from 'react'
import Icon1 from '../../images/undraw_factory_dy-0-a.svg'
import Icon2 from '../../images/undraw_stock_prices_re_js33.svg'
import Icon3 from '../../images/undraw_ideation_re_8i2h.svg'
import { ServicesContainer, ServicesH1, ServicesWrapper, ServicesCard, ServicesIcon, ServicesH2, ServicesP } from './ServicesElements'

const Services = () => {
  return (
    <>
        <ServicesContainer id="services">
            <ServicesH1>Our Services</ServicesH1>
            <ServicesWrapper>
                <ServicesCard>
                    <ServicesIcon src={Icon1}/>
                    <ServicesH2>Industrial Comissioning</ServicesH2>
                    <ServicesP>Allowed numerous companies build business plans, find contracts worldwide, source equipment, and install factories in AFRICA</ServicesP>
                </ServicesCard>
                <ServicesCard>
                    <ServicesIcon src={Icon2}/>
                    <ServicesH2>International Trade</ServicesH2>
                    <ServicesP>Supported African companies import goods and services from international leaders for the past 10 years using a private and customized approach</ServicesP>
                </ServicesCard>
                <ServicesCard>
                    <ServicesIcon src={Icon3}/>
                    <ServicesH2>Innovation</ServicesH2>
                    <ServicesP>Providing custom solutions in sustainable agriculture, renewable energy, and IT Development by bringing together best in class companies</ServicesP>
                </ServicesCard>
            </ServicesWrapper>
        </ServicesContainer>
    </>
  )
}

export default Services