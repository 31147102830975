const contactConfig = {
  YOUR_EMAIL: 'management@kuzog.com',
  YOUR_FONE: '(555)123-4567',
  description:
    'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi vehicula eu nunc et sollicitudin. Cras pulvinar, nisi at imperdiet pharetra. ',
  YOUR_SERVICE_ID: 'service_id',
  YOUR_TEMPLATE_ID: 'template_id',
  YOUR_USER_ID: 'user_id',
};

 
export {contactConfig};