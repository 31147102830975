import styled from "styled-components";
import { Link } from "react-router-dom"; 


export const Container = styled.div`
    display: grid;
    //grid-auto-rows: minmax(min-content, max-content);
    grid-template-rows: 100px;
    grid-auto-rows: auto;
    //min-height: 100%;
    //min-width:100%;
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom:0;
    z-index: 0;
    overflow: scroll;
    background: #010606;
`


export const FormWrap = styled.div`
    min-height: 100%;
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    
    padding-top: 64px;
    padding-bottom: 64px;
    padding-left: 200px;
    padding-right: 200px;




    @media screen and (max-width: 400px){
        height: 80%;
    }
`

export const Details = styled.div`
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
`
export const MapWrapper = styled.div`
    height: 60%;
    display: flex;
    flex-direction: column;
    justify-content: center;
`

export const IconWrapper = styled.div`
    padding: 32px;
    height: auto;
`


export const Icon = styled(Link)`
    //margin-left: 32px;
    text-decoration: none;
    color: white;
    font-weight: 700;
    font-size: 32px;
    width: 60px;

    @media screen and (max-width: 480px){
        margin-left: 16px;
        margin-top: 8px;
    }

    &:hover{
        color: #01BF71;
        transition: 0.1s ease-out;
    }
`

export const FormContent = styled.div`
    height: 100%;
    width: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;

    @media screen and (max-width: 480px){
        padding: 10px;
    }
`

export const Form = styled.form`
    background: #101522;
    max-width: 400px;
    padding: 64px;
    display: grid;
    grid-template-columns: repeat(1, 1fr);

    grid-column-gap: 20px;
    row-gap: 1em;
    border-radius: 4px;
    box-shadow: 0 1px 3px rgba(0,0,0,0.9);

    &:nth-child(3){
        grid-column: 1 / 2;
        grid-row: 1 / 2; 
    }

    @media screen and (max-width: 400px){
        padding: 32px 32px;
    }
`

export const DetailsH1 = styled.h1`
    margin-bottom: 40px;
    font-size: 20px;
    color: white;
    font-weight: 400;
    text-align: left;
`

export const FormH1 = styled.h1`
    margin-bottom: 40px;
    font-size: 20px;
    color: white;
    font-weight: 400;
    text-align: center;
`

export const FormLabel = styled.label`
    //margin-bottom: 8px;
    font-size: 14px;
    color: white;
`

export const FormInput = styled.input`
    padding: 16px 16px;
    margin-bottom: 8px;
    border: none;
    border-radius: 4px;
`

export const Message = styled.textarea`
    padding: 16px 16px;
    margin-bottom: 8px;
    border: none;
    border-radius: 4px;
`

export const FormButton = styled.button`
    width: 50%;
    background: #01bf71;
    padding: 16px 0;
    border: none;
    border-radius: 4px;
    color: white;
    font-size: 20px;
    cursor: pointer;

    &:hover{
        transition: 0.2s ease-in-out;
        background: white;
        color: #01bf71;
    }
`

export const DetailsText = styled.span`
    text-align: left;
    margin-top: 24px;
    color: white;
    font-size: 14px;
    font-weight: bold;
`

export const Text = styled.span`
    text-align: center;
    margin-top: 24px;
    color: white;
    font-size: 14px;
`