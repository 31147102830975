import React, {useState, useEffect} from 'react'
import Video from '../../videos/video.mp4'
import Image from '../../images/bg.png'
import {Button} from '../ButtonElements'
import PreLoader from '../PreLoader'
import { PreLoaderContainer, PreLoaderImg } from '../PreLoader/PreLoaderElements'
import { HeroContainer, HeroBg, VideoBg,
    HeroContent,
    HeroH1,
    HeroP,
    HeroBtnWrapper,
    ArrowForward,
    ArrowRight,
    ImageBg } from './HeroElements'
import Gif from '../../videos/animation_640_l783bpzt.gif';

const HeroSection = () => {

    const [hover, setHover] = useState(false)

    const onHover = () => {
        setHover(!hover)
    }

    const [width, setWidth] = useState(window.innerWidth);

    function handleWindowSizeChange() {
    setWidth(window.innerWidth);
}
useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
        window.removeEventListener('resize', handleWindowSizeChange);
    }
}, []);

const isMobile = width <= 768;

console.log(isMobile)

  return (
    <HeroContainer id='home'>
        <HeroBg>
            {isMobile && <ImageBg src={Image}/>}
            <VideoBg autoPlay loop playsInLine muted="true" src={Video} type='video/mp4'/>
        </HeroBg>
        <HeroContent>
            <HeroH1>Connecting People And Places</HeroH1>
            <HeroP>Since 2012</HeroP>
            <HeroBtnWrapper>
                <Button to="/contactus" onMouseEnter={onHover} onMouseLeave={onHover} primary = 'true' dark = 'true' smooth={true} duration={500} spy={true} exact="true" offset={-80}>
                    Get In Touch {hover ? <ArrowForward /> : <ArrowRight />}
                </Button>
            </HeroBtnWrapper>
        </HeroContent>
    </HeroContainer>
  )
}

export default HeroSection