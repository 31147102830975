

import React, { useRef, useState } from 'react'
import { useForm } from 'react-hook-form';
import { ToastContainer, toast } from 'react-toastify';

import { Container, Row, Col } from "react-bootstrap";
import { contactConfig } from "../ContactUs/content_option.js";
import { Form, FormButton, FormContent, FormH1, FormInput, FormLabel, FormWrap, Icon, Text, mycontainer } from './ContactUsElements'
import "./thirdElements.css"

import emailjs from '@emailjs/browser';
import 'react-toastify/dist/ReactToastify.min.css';

const ContactUs = () => {

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors }
  } = useForm();
  const [disabled, setDisabled] = useState(false);

  // Function that displays a success toast on bottom right of the page when form submission is successful
  const toastifySuccess = () => {
    toast('Form sent!', {
      position: 'bottom-right',
      autoClose: 5000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: false,
      className: 'submit-feedback success',
      toastId: 'notifyToast'
    });
  };

  // Function called on submit that uses emailjs to send email of valid contact form
  const onSubmit = async (data) => {
    // Destrcture data object
    const { from_name, email, message} = data;
    try {
      // Disable form while processing submission
      setDisabled(true);

      // Define template params
      const templateParams = {
        from_name,
        email,
        message
      };

      // Use emailjs to email contact form data
      await emailjs.send('service_zysm1yj', 'template_2nz3045', templateParams, 'KziW2tQwEyv7l2c7c'
      );

      // Reset contact form fields after submission
      reset();
      // Display success toast
      toastifySuccess();
      // Re-enable form submission
      setDisabled(false);
    } catch (e) {
      console.log(e);
    }
  };

  return (
  <div className='background'>
    <Container className='container'>
        <Icon to='/'>KUZOG</Icon>
        <Row className="mb-5 mt-3">
          <Col lg="12">
            <hr className="t_border my-4 ml-0 text-left" />
          </Col>
        </Row>
        <Row className="sec_sp gx-5" >
          <Col lg="5" className="mb-5">
          <div class="col-md-12">
            <h3 className="color_sec py-4">Get in touch</h3>
            <address>
              <strong className='color_sec'>Email:</strong>{" "}
              <a className="color_sec" href={`mailto:${contactConfig.YOUR_EMAIL}`}>
                {contactConfig.YOUR_EMAIL}
              </a>
              <br />
              <br />
            </address>
            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d184552.57418892003!2d-79.51848472142964!3d43.71815524282215!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89d4cb90d7c63ba5%3A0x323555502ab4c477!2sToronto%2C%20ON!5e0!3m2!1sen!2sca!4v1665172128949!5m2!1sen!2sca" width='100%' height="300" style={{ border: "0"}} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe> 
          </div>
          </Col>
          <Col lg="1"  xs="2"></Col>
          <Col lg="6" className="d-flex align-items-center">
          <div class="col-md-12">
            <form  onSubmit={handleSubmit(onSubmit)} noValidate className="contact__form w-100">
              <Row>
                <Col lg="6" className="form-group">
                <input className="form-control" type='text' name='from_name' {...register('from_name', {	
                        required: {	
                          value: true,	
                          message: 'Please enter your name'	
                        },	
                        maxLength: {	
                          value: 30,	
                          message: 'Please use 30 characters or less'	
                        }	
                      })} placeholder='Name' />	
                      {errors.name && <span className='errorMessage'>{errors.name.message}</span>}
                </Col>
                <Col lg="6" className="form-group">
                <input className="form-control rounded-0" type='email' name='email' {...register('email', {	
                        required: true,	
                        pattern:	
                          /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/	
                      })}	placeholder='example@example.com' />	
                      {errors.email && <span className='errorMessage'>Please enter a valid email address</span>}
                </Col>
              </Row>
              <textarea	className="form-control rounded-0"
                      rows={5}	
                      name='message'	
                      {...register('message', {	
                        required: true	
                      })}	
                      placeholder='Message'	
                    ></textarea>	
                    {errors.message && <span className='errorMessage'>Please enter a message</span>}
              <br />
              <Row>
                <Col lg="12" className="form-group text-center">
                <FormButton type='submit' disabled={disabled}>Send</FormButton>
                </Col>
              </Row>
            </form>
            </div>
          </Col>
        </Row>
        <ToastContainer />
      </Container>
      </div>
  )
}

export default ContactUs



/*

import React, { useState } from "react";
import {IconWrapper, Icon} from './ContactUsElements';
import "./secondElements.css";

const ContactUs = () => {

  const submitData = async (event) => {
    event.preventDefault();
    console.log("hi");
  };

  return (
    <>
      <section className="contactus-section">
      <IconWrapper>
            <Icon to='/'>KUZOG</Icon>
      </IconWrapper>
        <div className="container">
          <div className="row">
            <div className="col-12 col-lg-10 mx-auto">
              <div className="row">
                <div className="contact-leftside col-12 col-lg-5">
                  <h1 className="main-heading fw-bold">
                    Connect With Our <br /> Sales Team.
                  </h1>
                  <p className="main-hero-para">
                    Lorem ipsum dolor sit amet consectetur adipisicing elit.
                    Deserunt eaque alias similique.
                  </p>
                  <figure>
                  <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d184552.57418892003!2d-79.51848472142964!3d43.71815524282215!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89d4cb90d7c63ba5%3A0x323555502ab4c477!2sToronto%2C%20ON!5e0!3m2!1sen!2sca!4v1665172128949!5m2!1sen!2sca" width="400" height="300" style={{ border: "0"}} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                  </figure>
                </div>
                
                <div className="contact-rightside col-12 col-lg-7">
                  <form method="POST">
                    <div className="row">
                      <div className="col-12 col-lg-6 contact-input-feild">
                        <input
                          type="text"
                          name="firstName"
                          id=""
                          className="form-control"
                          placeholder="First Name"
                        />
                      </div>
                      <div className="col-12 col-lg-6 contact-input-feild">
                        <input
                          type="text"
                          name="lastName"
                          id=""
                          className="form-control"
                          placeholder="Last Name"
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-12 col-lg-6 contact-input-feild">
                        <input
                          type="text"
                          name="phone"
                          id=""
                          className="form-control"
                          placeholder="Phone Number "
                        />
                      </div>
                      <div className="col-12 col-lg-6 contact-input-feild">
                        <input
                          type="text"
                          name="email"
                          id=""
                          className="form-control"
                          placeholder="Email ID"
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-12 contact-input-feild">
                        <input
                          type="text"
                          name="address"
                          id=""
                          className="form-control"
                          placeholder="Add Address"
                        />
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-12 ">
                        <input
                          type="text"
                          name="message"
                          id=""
                          className="form-control"
                          placeholder="Enter Your Message"
                        />
                      </div>
                    </div>
                    <div class="form-check form-checkbox-style">
                      <input
                        class="form-check-input"
                        type="checkbox"
                        value=""
                        id="flexCheckChecked"
                      />
                      <label
                        class="form-check-label"
                        className="main-hero-para">
                        I agree that the thapatechnicalpay may contact me at the
                        email address or phone number above
                      </label>
                    </div>

                    <button
                      type="submit"
                      className="btn btn-style w-100"
                      onClick={submitData}>
                      Submit
                    </button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ContactUs;

*/


/*


import React, { useRef, useState } from 'react'
import { useForm } from 'react-hook-form';
import { ToastContainer, toast } from 'react-toastify';
import { Form, FormButton, FormContent, FormH1, FormInput, FormLabel, FormWrap, Container, Icon, Text, Details, IconWrapper, DetailsH1, DetailsText, MapWrapper, Message } from './ContactUsElements'

import emailjs from '@emailjs/browser';
import 'react-toastify/dist/ReactToastify.min.css';


const ContactUs = () => {

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors }
  } = useForm();
  const [disabled, setDisabled] = useState(false);

  // Function that displays a success toast on bottom right of the page when form submission is successful
  const toastifySuccess = () => {
    toast('Form sent!', {
      position: 'bottom-right',
      autoClose: 5000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: false,
      className: 'submit-feedback success',
      toastId: 'notifyToast'
    });
  };

  // Function called on submit that uses emailjs to send email of valid contact form
  const onSubmit = async (data) => {
    // Destrcture data object
    const { name, email} = data;
    try {
      // Disable form while processing submission
      setDisabled(true);

      // Define template params
      const templateParams = {
        name,
        email,
      };

      // Use emailjs to email contact form data
      await emailjs.send('service_zysm1yj', 'template_2nz3045', templateParams, 'KziW2tQwEyv7l2c7c'
      );

      // Reset contact form fields after submission
      reset();
      // Display success toast
      toastifySuccess();
      // Re-enable form submission
      setDisabled(false);
    } catch (e) {
      console.log(e);
    }
  };

  return (
        <Container>
          <IconWrapper>
            <Icon to='/'>KUZOG</Icon>
          </IconWrapper>
            <FormWrap>
                <Details>
                  <MapWrapper>
                  <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d184552.57418892003!2d-79.51848472142964!3d43.71815524282215!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89d4cb90d7c63ba5%3A0x323555502ab4c477!2sToronto%2C%20ON!5e0!3m2!1sen!2sca!4v1665172128949!5m2!1sen!2sca" width="400" height="300" style={{ border: "0"}} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                  </MapWrapper>
                  <DetailsText>Email: management@kuzog.com</DetailsText>
                  <DetailsText>Phone: 6474488681</DetailsText>
                </Details>
                <FormContent>
                    <Form onSubmit={handleSubmit(onSubmit)} noValidate>
                        <FormLabel htmlFor='Name'>Name</FormLabel>
                        <FormInput type='text' name='from_name' {...register('name', {
                        required: {
                          value: true,
                          message: 'Please enter your name'
                        },
                        maxLength: {
                          value: 30,
                          message: 'Please use 30 characters or less'
                        }
                      })} placeholder='Name' />
                      {errors.name && <span className='errorMessage'>{errors.name.message}</span>}
                        <FormLabel htmlFor='email'>Email</FormLabel>
                        <FormInput type='email' name='email' {...register('email', {
                        required: true,
                        pattern:
                          /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
                      })}
                      placeholder='example@example.com'  />
                      {errors.email && (
                      <span className='errorMessage'>Please enter a valid email address</span>
                    )}
                        <FormLabel htmlFor='message'>Message</FormLabel>
                        <Message
                      rows={5}
                      name='message'
                      {...register('message', {
                        required: true
                      })}
                      placeholder='Message'
                    ></Message>
                    {errors.message && <span className='errorMessage'>Please enter a message</span>}
                  
                        <FormButton type='submit' disabled={disabled}>Send</FormButton>
                    </Form>
                </FormContent>
            </FormWrap>
          <ToastContainer />
        </Container>
  )
}

export default ContactUs

*/





/*


import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { ToastContainer, toast } from 'react-toastify';
import emailjs from 'emailjs-com';
import 'react-toastify/dist/ReactToastify.min.css';

const ContactForm = () => {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors }
  } = useForm();
  const [disabled, setDisabled] = useState(false);

  // Function that displays a success toast on bottom right of the page when form submission is successful
  const toastifySuccess = () => {
    toast('Form sent!', {
      position: 'bottom-right',
      autoClose: 5000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: false,
      className: 'submit-feedback success',
      toastId: 'notifyToast'
    });
  };

  // Function called on submit that uses emailjs to send email of valid contact form
  const onSubmit = async (data) => {
    // Destrcture data object
    const { name, email} = data;
    try {
      // Disable form while processing submission
      setDisabled(true);

      // Define template params
      const templateParams = {
        name,
        email,
      };

      // Use emailjs to email contact form data
      await emailjs.send('service_zysm1yj', 'template_2nz3045', templateParams, 'KziW2tQwEyv7l2c7c'
      );

      // Reset contact form fields after submission
      reset();
      // Display success toast
      toastifySuccess();
      // Re-enable form submission
      setDisabled(false);
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <div className='ContactForm'>
      <div className='container'>
        <div className='row'>
          <div className='col-12 text-center'>
            <div className='contactForm'>
              <form id='contact-form' onSubmit={handleSubmit(onSubmit)} noValidate>
                


                <div className='row formRow'>
                  <div className='col-6'>
                    <input
                      type='text'
                      name='name'
                      {...register('name', {
                        required: {
                          value: true,
                          message: 'Please enter your name'
                        },
                        maxLength: {
                          value: 30,
                          message: 'Please use 30 characters or less'
                        }
                      })}
                      className='form-control formInput'
                      placeholder='Name'
                    ></input>
                    {errors.name && <span className='errorMessage'>{errors.name.message}</span>}
                  </div>
                  <div className='col-6'>
                    <input
                      type='email'
                      name='email'
                      {...register('email', {
                        required: true,
                        pattern:
                          /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
                      })}
                      className='form-control formInput'
                      placeholder='Email address'
                    ></input>
                    {errors.email && (
                      <span className='errorMessage'>Please enter a valid email address</span>
                    )}
                  </div>
                </div>
                


                <div className='row formRow'>
                  <div className='col'>
                    <input
                      type='text'
                      name='subject'
                      {...register('subject', {
                        required: {
                          value: true,
                          message: 'Please enter a subject'
                        },
                        maxLength: {
                          value: 75,
                          message: 'Subject cannot exceed 75 characters'
                        }
                      })}
                      className='form-control formInput'
                      placeholder='Subject'
                    ></input>
                    {errors.subject && (
                      <span className='errorMessage'>{errors.subject.message}</span>
                    )}
                  </div>
                </div>
                

                <div className='row formRow'>
                  <div className='col'>
                    <textarea
                      rows={3}
                      name='message'
                      {...register('message', {
                        required: true
                      })}
                      className='form-control formInput'
                      placeholder='Message'
                    ></textarea>
                    {errors.message && <span className='errorMessage'>Please enter a message</span>}
                  </div>
                </div>

                <button className='submit-btn' disabled={disabled} type='submit'>
                  Submit
                </button>
              </form>
            </div>
            <ToastContainer />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactForm;



*/













/*

<Form onSubmit={sendEmail}>
                        <FormLabel htmlFor='Name'>Name</FormLabel>
                        <FormInput type='text' name='from_name' required />
                        <FormLabel htmlFor='email'>Email</FormLabel>
                        <FormInput type='email' name='email' required />
                        <FormButton type='submit'>Send</FormButton>
                    </Form>


                    */
