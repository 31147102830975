import React, {useState, useEffect} from 'react'
import HeroSection from '../components/HeroSection'
import InfoSection from '../components/InfoSection'
import Navbar from '../components/Navbar'
import Sidebar from '../components/Sidebar'
import { homeObjOne } from '../components/InfoSection/Data'
import { homeObjTwo } from '../components/InfoSection/Data'
import Services from '../components/Services'
import { homeObjThree } from '../components/InfoSection/Data'
import Footer from '../components/Footer'
import {motion} from 'framer-motion'
import PreLoader from '../components/PreLoader'
import {AnimatePresence} from "framer-motion";
import ScrollToTop from '../components/ScrollToTop'
import { PreLoaderContainer, PreLoaderImg } from '../components/PreLoader/PreLoaderElements'

import Gif from '../videos/animation_640_l783bpzt.gif';

const Home = () => {

    const [isOpen, setIsOpen] = useState(false)

    const toggle = () => {
        setIsOpen(!isOpen)
    }

    const [loading, setLoading] = useState(true)

    const [done, setDone] = useState(false)

    useEffect(() => {
      setTimeout(() => setLoading(false), 6000)
      setTimeout(() => setDone(true), 5000)
      //setTimeout(() => {
      //  navigate('/index')
      //}, 6000)
    }, [])

    const flip =  {
        rotateY: 180,
        x: -150,
        transition: {
          x: {
            ease: "easeInOut",
            duration: 4
          },
          rotateY: {
            duration: 4,
            delay: 4
          }
        }
    }

  return (
    <>
        <Sidebar isOpen = {isOpen} toggle = {toggle}/>
        <Navbar toggle = {toggle}/>
        <HeroSection />
        <InfoSection {...homeObjOne}/>
        <InfoSection {...homeObjTwo}/>
        <Services />
        <InfoSection {...homeObjThree}/>
        <Footer />
    </>
  )
}

export default Home

//<PreLoader />

//initial={{y:'100%', duration: 6000}} animate={{y:'0%', duration: 6000}}

//initial={{height:'100vh'}} animate={{height:0}} exit={{y: '-100vh', transition: {delay:1, duration: 1.5}}}

//{loading && <PreLoaderContainer initial={{height: '100%', innerWidth: "100%"}} animate={{y: '-100vh'}}>
//<PreLoaderImg  src={Gif} alt='preloader gif'/>
//</PreLoaderContainer>}

//initial={false} animate={{y: '-100vh'}} transition={{delay:7}}


/*

<motion.div initial={{opacity:0}} animate={{opacity:1, transition:{delay:7, duration:1.5}}}>
        {loading && <PreLoader />}
        {done && <>
        <Sidebar isOpen = {isOpen} toggle = {toggle}/>
        <Navbar toggle = {toggle}/>
        <HeroSection />
        <InfoSection {...homeObjOne}/>
        <InfoSection {...homeObjTwo}/>
        <Services />
        <InfoSection {...homeObjThree}/>
        <Footer /> </>}
    </motion.div>

  */