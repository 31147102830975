import React from 'react'
import ContactUs from '../components/ContactUs'
import ScrollToTop from '../components/ScrollToTop'

const ContactUsPage = () => {
  return (
    <>
      <ScrollToTop />
      <ContactUs />
    </>
  )
}

export default ContactUsPage