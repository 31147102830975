import styled from "styled-components";
import { motion } from 'framer-motion';

export const PreLoaderContainer = styled(motion.div)`
    
    width: 100%;
    height: 100vh;
    background-color: blue;
    z-index: 18;
    top: 0;
    left: 0;
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    

    /*

    background: #0c0c0c;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 30px;
    height: 100vh;
    position: relative;
    z-index: 18;
    */
`

export const PreLoaderImg = styled.img`
    max-width:300px;
    height: auto;
`



/*

    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;

    transition: 0.3s ease-in-out;
    opacity: ${({ loading }) => (loading ? '100%' : '0')};
    top: ${({ loading }) => (loading ? '0' : '-100%')};

*/


//background-color: #010106;