import React from 'react'
import {BrowserRouter as Router, Routes, Route, useLocation} from 'react-router-dom'
import Home from '../pages'
import { useEffect, useState } from 'react';
import {AnimatePresence} from "framer-motion";
import { useNavigate } from 'react-router-dom'
import ContactUsPage from '../pages/contactus';


function AnimatedRoutes() {

    const location = useLocation();

    const [loading, setLoading] = useState(true)

    useEffect(() => {
      setTimeout(() => setLoading(false), 6000)
      //setTimeout(() => {
      //  navigate('/index')
      //}, 6000)
    }, [])

    const navigate = useNavigate()

  return (
    <AnimatePresence wait initial={false}>
        <Routes location={location} key={location.pathname}>
            <Route path="/" element={<Home />} exact />
            <Route path="/contactus" element={<ContactUsPage />} exact />
        </Routes>
    </AnimatePresence>
  )
}

export default AnimatedRoutes

// location={location} key={location.pathname}
// { loading && <Route path='/' element={<PreLoaderPage />} exact /> }

//<Route path="/" element={<PreLoaderPage />} exact />