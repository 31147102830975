
import './App.css';
import {BrowserRouter as Router} from 'react-router-dom'
import { useEffect, useState } from 'react';
import AnimatedRoutes from './components/AnimatedRoutes';
import { createBrowserHistory } from 'history'

function App() {

  const [loading, setLoading] = useState(true)

    useEffect(() => {
      setTimeout(() => setLoading(false), 6000)
    })

  return (
    <Router history={createBrowserHistory}>
      <AnimatedRoutes />
    </Router>
  );
}

export default App;

//{ loading && <Route path='/preloader' element={<PreLoaderPage />} exact /> }
//<Route path="/" element={<Home />} exact />

/*
<TransitionGroup component="div" className="App">
      <CSSTransition timeout={timeout} classNames="pageSlider" mountOnEnter={false} unmountOnExit={true}></CSSTransition>
*/