import React from 'react'
import { FaFacebook, FaInstagram, FaYoutube, FaLinkedin } from 'react-icons/fa'
import { FooterContainer, FooterWrap, FooterLinksContainer, FooterLinksWrapper, FooterLinksItems, FooterLinkTitle, FooterLinkS, FooterLinkR } from './FooterElements'
import { SocialMedia, SocialMediaWrap, SocialLogo, WebsiteRights, SocialIcons, SocialIconLink } from './FooterElements'
import { animateScroll as scroll } from 'react-scroll'

const Footer = () => {

    const toggleHome = () => {
        scroll.scrollToTop();
    }

  return (
    <>
        <FooterContainer>
            <FooterWrap>
                <FooterLinksContainer>
                    <FooterLinksWrapper>
                        <FooterLinksItems>
                            <FooterLinkS to="about" smooth={false} duration={500} spy={true} exact="true" offset={-80}>About Us</FooterLinkS>
                        </FooterLinksItems>
                        <FooterLinksItems>
                            <FooterLinkS to="industries" smooth={false} duration={500} spy={true} exact="true" offset={-80}>How it works</FooterLinkS>
                        </FooterLinksItems>
                        <FooterLinksItems>
                            <FooterLinkS to="services" smooth={false} duration={500} spy={true} exact="true" offset={-80}>Services</FooterLinkS>
                        </FooterLinksItems>
                        <FooterLinksItems>
                            <FooterLinkS to="careers" smooth={false} duration={500} spy={true} exact="true" offset={-80}>Careers</FooterLinkS>
                        </FooterLinksItems>
                        <FooterLinksItems>
                            <FooterLinkR to='/contactus'>Contact Us</FooterLinkR>
                        </FooterLinksItems>
                    </FooterLinksWrapper>
                </FooterLinksContainer>
                <SocialMedia>
                    <SocialMediaWrap>
                        <SocialLogo to='/' onClick={toggleHome}>KUZOG</SocialLogo>
                        <WebsiteRights>KUZOG © {new Date().getFullYear()} All Rights Reserved</WebsiteRights>
                    </SocialMediaWrap>
                </SocialMedia>
            </FooterWrap>
        </FooterContainer>
    </>
  )
}

export default Footer